<template>
    <v-container>
        <v-row justify="center">
            <v-col align="center" lg=6 xl=6>
        <v-card class="component1" style="border-radius: 30px">
            <v-card-title class="pa-0" >
                <v-row>
                    <v-col align="center" class="headline pt-0" width="100%" lg=12>
                        <v-img src="./background.svg" class="gradientBG pb-9"><h6 class="pb-5white--text"><br>Wallet</h6><br>
  <h1>$1678.30</h1><h6 class="grey--text subtitle-1"><br>+$36.80 (3.6%) <v-icon class="primary--text">mdi-arrow-up</v-icon></h6></v-img>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols=12>
                        Transactions
                    </v-col>
                    <v-col cols=12>
                        <v-card elevation=24 class="ma-3 txBG" style="border-radius: 20px">
                            <v-card-text>
                                <v-row dense align="center">
                                    <v-col cols=12 lg=2>
                                        <v-icon color="primary">mdi-arrow-right-bold</v-icon>
                                    </v-col>
                                    <v-col cols=12 lg=4>
                                        <v-row no-gutters>
                                            <v-col class="headline white--text" lg=12>
                                                Deposit
                                            </v-col>
                                            <v-col lg=12>
                                                02/03/2020
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="headline white--text" lg=3>
                                        $123.56
                                    </v-col>
                                    <v-col class="primary--text" lg=3>
                                        +8.4%
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols=12>
                        <v-card elevation=24 class="ma-3 txBG" style="border-radius: 20px">
                            <v-card-text>
                                <v-row dense align="center">
                                    <v-col lg=2>
                                        <v-icon color="error">mdi-arrow-left-bold</v-icon>
                                    </v-col>
                                    <v-col lg=4>
                                        <v-row no-gutters>
                                            <v-col class="headline white--text" lg=12>
                                                Investment
                                            </v-col>
                                            <v-col lg=12>
                                                02/03/2020
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="headline white--text" lg=3>
                                        $123.56
                                    </v-col>
                                    <v-col class="error--text" lg=3>
                                        -8.4%
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols=12>
                        <v-card elevation=24 class="ma-3 txBG" style="border-radius: 20px">
                            <v-card-text>
                                <v-row dense align="center">
                                    <v-col lg=2>
                                        <v-icon color="error">mdi-arrow-left-bold</v-icon>
                                    </v-col>
                                    <v-col lg=4>
                                        <v-row no-gutters>
                                            <v-col class="headline white--text" lg=12>
                                                Deposit
                                            </v-col>
                                            <v-col lg=12>
                                                02/03/2020
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="headline white--text" lg=3>
                                        $123.56
                                    </v-col>
                                    <v-col class="error--text" lg=3>
                                        -8.4%
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col lg=12>
    <v-pagination
      v-model="page"
      :length="4"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      color="primary darken-3"
    ></v-pagination>
                    </v-col>
                    <v-col class="gradient font-weight-bold" cols=6 lg=6>
                        <v-icon class="gradient">mdi-wallet-plus</v-icon> Deposit
                    </v-col>
                    <v-col class="errorGradient font-weight-bold" cols=6 lg=6>
                        <v-icon class="errorGradient">mdi-cash-usd</v-icon> Withdraw
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            page: 1
        }
    }
}
</script>

<style scoped>
.gradientBG {
 transition: 0.5s;
  background: -webkit-linear-gradient(135deg, #1B1B1B  -10%,#0EF9AE 120%);
  border-radius: 30px
}

.txBG {
    transition: 0.5s;
    background: -webkit-linear-gradient(135deg, #101010 20%, #272727 100%)
}

.errorGradient {
    transition: 0.5s;
    background: -webkit-linear-gradient(135deg, #D14242  -10%,#515151 120%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    }



</style>

